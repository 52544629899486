import LABELS from 'labels';
import CONFIG from 'config';
import authService from 'redux/auth';
import axios from 'axios';

/**
 * @description this function creates copy of an object
 * @param obj as an object 
 * @returns object copy
 */
export const copyObject = (obj) => {
  if (!!obj && typeof obj === 'object') {
    return JSON.parse(JSON.stringify(obj));
  }
  return obj;
};

/**
 * @desc this method will scroll page to the top
 */
export const scrollToTop = (location = 0) => {
  window.scrollTo({ top: location, behavior: 'smooth' });
}


export const isPreviewDisabled = (kpItem) => {
  const {
    isURLAdded,
    attachment: {
      fileName
    }
  } = kpItem;

  if (isURLAdded === '1') {
    // if url type kp and if not a supported docviz type  hide preview 
    return true;
  } else {
    return false;
  }
}
export const checkIsGivenValueIsPresentInObj = (value, key) => {
  if (typeof value === 'object' && value.hasOwnProperty(key)) {
    return true;
  }
  return false
}

const findItemNested = (arr, itemId, nestingKey) => (
  arr.reduce((a, item) => {
    if (a) return a;
    if (item.Id === itemId) return item;
    if (item[nestingKey]) return findItemNested(item[nestingKey], itemId, nestingKey)
  }, null)
);

const getPolyIdDataFromTaxonomyList = (polyId, taxonomyTypeData) => {
  return findItemNested(taxonomyTypeData, polyId, "Children");
}
/** 
 * @description method to get current window location 
*/
const checkIfIdIsPolyHierarchcal = (selectedTaxonomyId) => {
  const { POLY_HIERARCHICAL } = CONFIG;
  let polyHierarchicalIdList = [];
  try {
    for (let i = 0; i < POLY_HIERARCHICAL.length; i++) {
      let hierarchicalData = POLY_HIERARCHICAL[i];
      let hierarchicalDataFound = false;
      for (let j = 0; j < hierarchicalData.length; j++) {
        let hierarchicalObj = hierarchicalData[j];
        const { id } = hierarchicalObj;
        if (id === selectedTaxonomyId) {
          polyHierarchicalIdList = JSON.parse(JSON.stringify(hierarchicalData));
          hierarchicalDataFound = true;
          break;
        }
        if (hierarchicalDataFound) {
          break;
        }
      }
    }
  } catch (error) {
    console.log('Error in checkIfIdIsPolyHierarchcal function ', error)
  }
  return polyHierarchicalIdList
}

const updatedPolyHierarchalList = (selectedTaxonomyId, listOfPolyHierarchiIDs, taxonomyTypeData) => {
  let updatedPolyData = [];
  try {
    listOfPolyHierarchiIDs.map(item => {
      let obj = getPolyIdDataFromTaxonomyList(item.id, taxonomyTypeData[item.type])
      if (obj) {
        obj.type = item.type;
      }
      updatedPolyData.push(obj)
      return item;
    })
  } catch (error) {
    console.log('Error in updatedPolyHierarchalList function ', error)
  }
  return updatedPolyData;
}


export const getmyCurrentLocation = () => {
  if (window) {
    return window.location.href;
  }
  return null;
}

/**
 * Returns the text from a HTML string
 * 
 * @param {html} String The html string
 */
export const stripHtml = (html) => {
  // Create a new div element
  var temporalDivElement = document.createElement("div");
  // Set the HTML content with the providen
  temporalDivElement.innerHTML = html;
  // Retrieve the text property of the element (cross-browser support)
  return temporalDivElement.textContent || temporalDivElement.innerText || "";
}

/**
 * @description trim string by charlimit but last word intac
 * @param {yourString} String 
 * @returns trimmedString
 */

export const noWordsTrimmingInString = (yourString, maxLength) => {
  let trimmedString = '';
  //trim the string to the maximum length
  trimmedString = yourString.substr(0, maxLength);
  //re-trim if we are in the middle of a word and 
  trimmedString = trimmedString.substr(0, Math.min(trimmedString.length, trimmedString.lastIndexOf(" ")))

  return trimmedString;

}

// Check if the browser is IE
export const isIE = () => {
  // Check the userAgent property of the window.navigator object
  const ua = window.navigator.userAgent;
  // IE 10 or older
  const msie = ua.indexOf('MSIE ');
  // IE 11
  const trident = ua.indexOf('Trident/');
  return msie > -1 || trident > -1;
}


/**
 * Opens the url in new tab or same tab
 * @param {url} String
 * @param {newTab} boolean
 */
export const openURL = (url, newTab) => {
  if (newTab) {
    window.open(url, '_blank');
  }
  else {
    window.open(url);
  }
}


export const removeCurlyBracketFromId = (id) => {
  return id?.indexOf('{') > -1 ? id.substring(1, id.length - 1) : id
}

export const removeCurlyBrackets = (ids = []) => {
  if (Array.isArray(ids))
    return ids.map(id => removeCurlyBracketFromId(id));
  else
    return removeCurlyBracketFromId(ids)
}

export const isValidData = (content) => {
  const { GLOBAL: { RESTRICTED } } = LABELS;
  if (typeof content === "undefined" || !content.length || content.trim === "" || content.toLowerCase() === 'null' || content.trim().toLowerCase() === RESTRICTED) {
    return false
  } else {
    return true;
  }
}

export const addColonToValidData = (content) => {
  return isValidData(content) ? content + " : " : ""
}
export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export const getTimeStampDate = (date) => {
  if (date && date.length > 0) {
    const dateArr = date.split("-")
    return new Date(`${dateArr[1]}/${dateArr[2]}/${dateArr[0]}`).getTime()
  }
}

export const sortArrayObjectByDate = (data, key) => {
  if (data || data.length > 0) {
    return data.sort((a, b) => getTimeStampDate(b.data[key]) - getTimeStampDate(a.data[key]))
  }
}

export const replaceInLabel = (originalString, searchValue, replaceWith) => {
  if (Array.isArray(replaceWith)) {
    let replacestr = originalString;
    replaceWith.forEach(current => {
      replacestr = replacestr.replace(searchValue, current)
    })
    return replacestr;
  } else {
    return originalString && originalString.replace(searchValue, replaceWith)
  }
}

export const getStartEndPagination = (size, number, total) => {
  return {
    start: size * (number - 1) + 1,
    end: size * number > total ? total : size * number,
  }
}

export const UpdatePageUrlWithoutRefresh = (url, history) => {
  history.replace({
    pathname: url,
    state: { mode: history.location?.state?.mode },
  });
}

export const UpdatePageQueryParamsWithoutRefresh = (url, history, search) => {
  history.replace({
    pathname: url,
    search
  });
}

export const checkIfAllQueryStringsExist = (url, qsCollection) => {
  let isContain = false;
  for (var i = 0; i < qsCollection.length; i++) {
    if (url.indexOf(qsCollection[i]) !== -1) {
      isContain = true;
    }
  }
  return isContain;
}

/**
 * @description - Check both arrays contains same element
 * @param - array1, array 2
 * @returns - boolean
*/
export const findCommonElements = (arr1, arr2) => {
  return arr1.some(item => arr2.includes(item))
}

export const checkIsGivenValueIsAArray = (value) => {
  if (value && Array.isArray(value))
    return true;

  return false
}

export const checkIsGivenValueIsAObj = (value) => {
  if (typeof value === 'object')
    return true;

  return false
}

/**
 * @desc check whether an object is empty or not
 * @param {Object} obj ==> object to be checked for emptiness
 * @returns {Boolean} true if isEmpty else false
 */
export const isObjEmpty = (obj) => {
  for (let prop in obj) {
    if (obj.hasOwnProperty(prop))
      return false;
  }
  return true;
}

/**
 * Function used to return list of polyhierarchi data
 * @param {*} selectedTaxonomyId 
 * @param {*} taxonomyTypeData 
 * @returns 
 */
export const getPolyHierarchiData = (selectedTaxonomyId, taxonomyTypeData) => {
  let obj = {
    isPolyHierarchiDataPresent: false,
    polyHierarchiIdsList: []
  }
  try {
    let polyHierarchiResponseList = checkIfIdIsPolyHierarchcal(selectedTaxonomyId);
    if (polyHierarchiResponseList.length > 0) {
      obj.isPolyHierarchiDataPresent = true;
      obj.polyHierarchiIdsList = updatedPolyHierarchalList(selectedTaxonomyId, polyHierarchiResponseList, taxonomyTypeData);
    }
  } catch (error) {
    console.log('Error in getPolyHierarchiData function ', error)
  }
  return obj;
}

export const helperFnToCreatePolyHGuidToObj = () => {
  const { POLY_HIERARCHICAL } = CONFIG;
  let obj = {}
  let polyData = JSON.parse(JSON.stringify(POLY_HIERARCHICAL));

  polyData.map(polyOuterArray => {
    polyOuterArray.map(polyInnerItems => {
      const { id } = polyInnerItems;
      obj[id] = id;
      return polyInnerItems;
    })
    return polyOuterArray
  })
  return obj
}

const updateCorrectPolyHIdsData = (polyIdsList) => {
  let updatedPolyIdsList = [];

  for (let i = 0; i < polyIdsList.length; i++) {
    let polyHierarchiResponseList = checkIfIdIsPolyHierarchcal(polyIdsList[i]);

    if (polyHierarchiResponseList.length > 0) {
      if (updatedPolyIdsList.indexOf(polyHierarchiResponseList[0].id) === -1) {
        updatedPolyIdsList.push(polyHierarchiResponseList[0].id);
      }
    } else {
      if (updatedPolyIdsList.indexOf(polyIdsList[i]) === -1) {
        updatedPolyIdsList.push(polyIdsList[i])
      }
    }
  }

  return updatedPolyIdsList;
}

export const polyHMaxLimitValidation = (polyIdsList, maxLimit, singleID) => {
  try {
    let updatedPolyIdsList = updateCorrectPolyHIdsData(polyIdsList)

    let polyHierarchiResponseList = checkIfIdIsPolyHierarchcal(singleID);
    if (singleID && polyHierarchiResponseList.length > 0 && updatedPolyIdsList.length <= maxLimit && updatedPolyIdsList.indexOf(polyHierarchiResponseList[0].id) > -1) {
      return false;
    } else if (singleID && polyHierarchiResponseList === 0 && updatedPolyIdsList.length < maxLimit) {
      return false;
    } else if (updatedPolyIdsList.length < maxLimit) {
      return false;
    }
  } catch (error) {
    console.log('Error in polyHMaxLimitValidation function ', error)
  }

  return true;
}

export const getPolyHierarchiLength = (polyIdsList) => {
  let updatedPolyIdsList = updateCorrectPolyHIdsData(polyIdsList);
  return updatedPolyIdsList.length;
}

export const getLengthOfMultiArrayWithPoly = (practiseAreaTags) => {
  let allPracticeAreasIDsList = [];
  try {
    if (practiseAreaTags && Array.isArray(practiseAreaTags) && practiseAreaTags.length > 0) {
      let practiceAreaData = practiseAreaTags[0];

      for (let item in practiceAreaData) {
        let practiceAreaObj = practiceAreaData[item];
        practiceAreaObj.map(subItem => {
          allPracticeAreasIDsList.push(subItem.id)
          return subItem;
        })
      }
    }
  } catch (error) {
    console.log('Error in getLengthOfMultiArrayWithPoly function ', error)
  }

  return getPolyHierarchiLength(allPracticeAreasIDsList);
}

export const getHighLighterText = (searchWord, textToHighlight) => {
  let parts = [];
  try {
    let text_escaped = searchWord.replace(/([.^$|*+?()[\]{}\\-])/g, "\\$1");
    parts = textToHighlight.split(new RegExp(`(${text_escaped})`, 'gi'));
  } catch (error) {
    console.log('Error in getHighLighterText function ', error);
  }
  return parts;
}

export const getTagsInfoIconDetailsifAny = (item, infoConfig) => {
  let infoData = null;

  try {
    if (infoConfig && checkIsGivenValueIsAArray(infoConfig) && checkIsGivenValueIsAObj(item)) {
      infoConfig.map(infoItem => {
        const { infoIconKeyName } = infoItem;
        if (item[infoIconKeyName]) {
          infoData = {
            ...infoItem
          }
        }
        return infoItem;
      })
    }
  } catch (error) {
    console.log('Error in getTagsInfoIconDetailsifAny function ', error)
  }

  return infoData;
}

export const convertArrayToObject = (arr = []) => {
  const obj = {};

  try {
    if (arr && checkIsGivenValueIsAArray(arr))
      arr.forEach((element) => {
        if (checkIsGivenValueIsAObj(element) && checkIsGivenValueIsPresentInObj(element, 'Id')) {
          obj[`${element.Id}`] = element;
        }
      });
  } catch (error) {
    console.log('Error in convertArrayToObject function ', error)
  }

  return obj;
}

//Divide tickets into two array based on given number
export const splitKpIdsArray = (arr = [], num = 0) => {

  let updatedSplitIdsObj = {
    firstArr: [],
    lastArr: []
  }
  try {
    if (arr && checkIsGivenValueIsAArray(arr) && arr.length > 0) {
      for (let i = 0; i < arr.length; i++) {
        if (i < num) {
          updatedSplitIdsObj.firstArr.push(arr[i]);
        } else {
          updatedSplitIdsObj.lastArr.push(arr[i]);
        }
      }
    }
  } catch (error) {
    console.log('Error in splitKpIdsArray function ', error)
  }

  return updatedSplitIdsObj;
}

export const isDocvizNotSupported = (kpItem, slides = []) => {
  let isPreviewDisable = isPreviewDisabled(kpItem);

  return ((checkIsGivenValueIsAArray(slides) && slides.length === 0) || isPreviewDisable) ? true : false;
}

export const getElemDistanceFromTop = (elem_id) => {
  try {
    let elem = document.querySelector(`${elem_id}`);

    // Get an element's distance from the top of the page
    let location = 0;
    if (elem.offsetParent) {
      do {
        location += elem.offsetTop;
        elem = elem.offsetParent;
      } while (elem);
    }
    location = location >= 0 ? location - 180 : 0;

    scrollToTop(location);
  } catch (error) {
    console.log('Error in getElemDistance function ', error);
  }

}


/**
 * @desc this function toggles isOwner property to specific element and set false for all other
 * @param tabs as object of objects
 * @param pillId as string
 * @returns object with new isOwner property updated
 */
export const setOwnerForAllTabs = (tabs, pillId) => {
  const copy = copyObject(tabs);
  copy && Object.keys(copy).forEach(tab => {
    copy[tab] && Object.keys(copy[tab]).forEach(id => {
      if (id === pillId) {
        copy[tab][id].isOwner = !copy[tab][id].isOwner;
      }
      else {
        copy[tab][id].isOwner = false;
      }
    });
  });
  return copy;
};


export const filterInternalOfficeDataByGlobal = (data, keyname) => {
  const globalGUID = CONFIG.TAXONOMY_IDS['GLOBAL_INTERNAL_OFFICE'].toLowerCase();
  const newarr = data.filter(eachnode => (eachnode[keyname] !== globalGUID));
  return newarr;
}

/**
 * @desc: filter the region data by global ID and name
 * @param: data - array of objects
 * @returns: filtered data - array of objects 
 * */

export const filterRegionsDataByGlobal = (data, keyname) => {
  const globalGUID = CONFIG.TAXONOMY_IDS['GLOBAL_REGION'].toLowerCase();
  const newarr = data.filter(eachnode => (eachnode[keyname] !== globalGUID));
  return newarr;
}

export const handleCopy = async (ref) => {
  try {
    if (document.execCommand('copy') && navigator.userAgent.search("Firefox") !== -1) {
      const range = document.createRange();
      range.selectNodeContents(ref.current);
      const selectedAnswer = window.getSelection();
      selectedAnswer.removeAllRanges();
      selectedAnswer.addRange(range);
      document.execCommand('copy');
      selectedAnswer.removeAllRanges();
    } else {
      const clipboardText = ref.current.outerHTML;
      const data = [new window.ClipboardItem({ 'text/html': new Blob([clipboardText], { type: 'text/html' }) })];
      navigator.clipboard.write(data);
    }
  } catch (err) {
    console.log("Error copying to clipboard")
  }
}


export const scrollElementToPosition = (container, element, behavior = 'smooth') => {
  const containerRect = container.getBoundingClientRect();
  const elementRect = element.getBoundingClientRect();
  const topPosition = elementRect.top - containerRect.top + container.scrollTop;

  container.scrollTo({
    top: topPosition,
    behavior
  })
}

export const downloadFileFromUrl = (url, target) => {
  const tempLink = document.createElement('a');
  tempLink.href = url;
  if (target) {
    tempLink.target = target;
  }
  document.body.appendChild(tempLink);
  tempLink.click();
  tempLink.remove();
}

export const getMonthYearFromDate = (date) => {
  return `${date.toLocaleString('default', { month: 'short' })} ${date.getFullYear()}`;
}

export const getFormattedDate = (date) => {
  return `${date.toLocaleString('default', { month: 'short' })} ${date.getDate()}, ${date.getFullYear()}`;
}

export const isInViewport = (element) => {
  const rect = element.getBoundingClientRect();
  const scrollTop = rect.top;
  const scrollBottom = rect.bottom - (window.innerHeight || document.documentElement.clientHeight)
  return (
    (scrollTop > 0 ? false : scrollTop) ||
    (scrollBottom < 0 ? 0 : (scrollBottom + 5))
  );
}

export const getRandomString = (length) => {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let randomString = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * charset.length);
    randomString += charset[randomIndex];
  }

  return randomString;
};

export const downloadClippedFileFromUrl = async (clippedResponse, fileName) => {
  const clipBlob = new Blob([clippedResponse], { type: 'mime' });
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(clipBlob, `${fileName}`);
  } else {
    const clipDownloadResponse = window.URL.createObjectURL(clipBlob);
    const tempLink = document.createElement('a');
    tempLink.href = clipDownloadResponse;
    tempLink.setAttribute('download', `${fileName}`);
    document.body.appendChild(tempLink);
    tempLink.click();
    tempLink.remove();
  }
}

const updateItemNested = (arr, obj) => {
  try {
    if (arr.length === 0) return;

    for (let i = 0; i < arr.length; i++) {
      let item = arr[i];
      const { Id, Children, Path } = item;
      if (!obj[Id]) {
        obj[Id] = getUpdatePath(Path);
      }
      if (Children && Children.length > 0) {
        updateItemNested(Children, obj);
      }
    }
  } catch (error) {
    console.log('Error in updateItemNested function ', error)
  }
}

export const createTaxonomyDataPath = (data) => {
  let updatedObj = {};

  try {
    if (checkIsGivenValueIsAArray(data)) {
      updateItemNested(data, updatedObj);
    }
  } catch (error) {
    console.log('Error in createTaxonomyDataPath function ', error);
  }
  return updatedObj;

}

const getUpdatePath = (path) => {
  let responsePathArray = [];
  try {
    if (path) {
      let pathArray = path.split('/');

      if (pathArray && pathArray.length > 0) {
        let arrayIndex = getPathIndex(pathArray);

        if (arrayIndex) {
          for (let i = arrayIndex + 1; i < pathArray.length; i++) {
            if (pathArray[i]) {
              responsePathArray.push(pathArray[i])
            }
          }
        }
      }
    }
  } catch (error) {
    console.log('Error in getUpdatePath function ', error)
  }

  return responsePathArray;
}

const getPathIndex = (pathArray) => {
  let arrayIndex = null;
  try {
    const {
      TBDB_IDS: {
        FPA,
        IPA,
        BCG_INTERNAL
      }
    } = CONFIG;

    let functionalPracticeArea = {
      [FPA]: FPA,
      [IPA]: IPA,
      [BCG_INTERNAL]: BCG_INTERNAL
    };

    for (let i = 0; i < pathArray.length; i++) {
      if (pathArray[i] === functionalPracticeArea[pathArray[i]]) {
        arrayIndex = i;
        break;
      }
    }
  } catch (error) {
    console.log('Error in getPathIndex function ', error);
  }

  return arrayIndex;
}

/**
 * @desc returns true if all entitlement value are true/false(checkFor) else return false
 * @param {Array} entitlements: an array of entitlement object
 * @param {Boolean} checkFor: check all the entitlement value for the given Boolean - true or false
 * @returns boolean
 */
export const checkAllEntitlementValue = (entitlements, checkFor) => {
  if (typeof entitlements === 'undefined') {
    /**
     * This case is for protection when the entitlement call fails.
     * It will make sure that we have the least visibility by default/error 
     */
    return checkFor ? false : true;
  }
  return Object.prototype.toString.call(entitlements) === '[object Object]' ?
    Object.values(entitlements).every(val => val === checkFor) : false;
}
// get Query value from URL
export const getQueryParameter = (param) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(param);
};