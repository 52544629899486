import React, { useRef, useState, useEffect } from "react";
import { Answer, AnswerError } from "../../components/Answer";
import { SampleQueries } from "../../components/SampleQueries";
import { QuestionInput } from "../../components/QuestionInput";
import { connect } from "react-redux";
import { UserChatMessage } from "../../components/UserChatMessage";
import { getActiveUserSession } from "redux/actions/usersession/usersession.actions";
import { selectors as userSessionSelector } from "redux/reducers/usersession/usersession_reducer";
import { selectors as entitlementSelectors } from "redux/reducers/authorization/authorization_reducer";

import LABELS from "labels";
import { LoadingDotsFlashing } from "components/Loading/LoadingDotsFlashing";
import { Layout } from "containers/Layout";
import { useDispatch, useSelector } from "react-redux";
import { selectors as responseSelector } from "../../redux/reducers/response/response_reducer";
import { selectors as suggestedQuerySelector } from "redux/reducers/suggested_query/suggestedquery_reducer";
import { SUGGESTED_QUERY_DATA, RESPONSE_DATA, ROLES } from "redux/constants";

import Image from "components/shared/Image";
import ChatMainPageImage from "assets/images/AIChatLandingScreen.svg";
import BenefitContent from "assets/images/benefit-content.svg";
import CONFIG from "config";
import CONSTANTS from "globalConstants";
import { sendToLocalStorage, getFromLocalStorageAsJSON, removeFromLocalStorage } from 'utils/localStorage/localStorage';

import "./ChatBot.scss";
import { UpdatePageQueryParamsWithoutRefresh } from "utils/helpers/helpers";
import SampleQueryArrow from "assets/images/LineArrow.svg";
// Analytics
import ANALYTICS from "utils/analytics/analytics";
import {
    PAGES,
    PRIMARY_CATEGORY,
    DTMRULE
} from "utils/analytics/analytics_constants";

const {
    RETRIEVAL_API_RESULTS_SUCCESS,
    CLEAR_TRANSCRIPT_SELECTED,
    CHAT_ADD_MESSAGE,
    CHAT_CLEAR_ALL_MESSAGES,
    QUERY_CHAT_API,
    GET_CHAT_SEARCH_HISTORY_BY_ID,
    CHAT_SUCCESS,
    REMOVE_FETCH_CONTROLLER,
    MONTH_OF_INTERVIEW_VALUE,
    COMPANY_FILTER_APPLIED,
    SOURCE_FILTER_APPLIED,
    INDUSTRY_FILTER_APPLIED,
    FUNCTION_FILTER_APPLIED,
    CHAT_SET_HISTORY_ID
} = RESPONSE_DATA;

const {
    API_STATUS: {
        COMPLETED,
        ERROR,
        START,
        PENDING
    },
    ENTITLEMENTS: {
        TRANSCRIPT_LIBRARY,
        CHAT
    },
} = CONSTANTS;


const ChatBotContainer = ({
    activeUserSessionId,
    history,
    getCurrentUserSession,
    match,
    entitlementApiStatus,
    isChatEnabled,
    match: {
        params: { sessionId },
    },
    location
}) => {
    const { TL_GPT_ENGINE, NUM_OF_TRANSCRIPTS_TEXT } = CONFIG;
    const question = new URLSearchParams(location.search).get("query");
    const urlChatHistoryId = new URLSearchParams(location.search).get("chatHistoryId");
    const {
        CHAT_EMPTY_TITLE,
        CHAT_EMPTY_SUBTITLE,
        INPUT_EMPTY_HEADING,
        INPUT_HEADING,
        CLOSE
    } = LABELS;
    const dispatch = useDispatch();
    const isResponseLoading = useSelector(responseSelector.getLoading);
    const queryResponse = useSelector(responseSelector.getResponse);
    const queryResponseError = useSelector(responseSelector.getError)
    const errorMessage = useSelector(responseSelector.getErrorMessage)
    const messages = useSelector(responseSelector.getMessages)
    const chatHistoryId = useSelector(responseSelector.getChatHistoryId)
    const lastUserQues = messages.filter(m => m.role === ROLES.USER)?.pop()?.content;
    const fetchController = useSelector(responseSelector.getFetchController)
    const isChatBotScreen = window.location.search !== "";
    const lastQuestionRef = useRef("");
    const chatMessageStreamEnd = useRef(null);
    const pageURL = window.location.href;
    if (urlChatHistoryId) {
        lastQuestionRef.current = urlChatHistoryId;
    }
    const [performChat, setPerformChat] = useState(false);
    const [pageReload, setPageReload] = useState(false);

    const chatContainerElementRef = useRef(null);

    const makeApiRequest = (question, isRetry, isPageReload) => {
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Chatbot,
                    previousPageName: PAGES.Transcript_Library_Chatbot,
                    pageURL: window.location.origin + window.location.pathname + `?query=${question}`
                }
            },
            internal: {
                search: { term: question }
            }
        };

        setTimeout(function () {
            ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.INTERNAL_SEARCH)
        }, 0);

        if (!lastQuestionRef.current && pageReload) {
            // ANALYTICS.page.initialize({
            //     pageName: PAGES.Transcript_Library_Chatbot,
            //     previousPageName: PAGES.Transcript_Library_Keyword_Search,
            //     category: PRIMARY_CATEGORY.Transcript_Library,
            //     trackPageLoad: true
            // });
        }
        lastQuestionRef.current = question;
        // dispatch({
        //     type: QUERY_RESPONSE, payload: {
        //         question, resetFilters: true
        //     }
        // });
        dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: [] } });
        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });

        setPageReload(isPageReload);
        if (urlChatHistoryId) {
            dispatch({ type: GET_CHAT_SEARCH_HISTORY_BY_ID, payload: { urlChatHistoryId, dispatch } });
        } else {
            if (!isRetry) {
                const content = question;
                const userMessage = {
                    index: messages.length,
                    role: ROLES.USER,
                    content
                };
                dispatch({ type: CHAT_ADD_MESSAGE, payload: userMessage });
                setPerformChat(true);
            } else {
                dispatch({ type: QUERY_CHAT_API, payload: { messages, selectedEngine: TL_GPT_ENGINE, chatHistoryId, dispatch, pageReload: isPageReload } });
                setPerformChat(false);
            }
        }

        dispatch({ type: MONTH_OF_INTERVIEW_VALUE, payload: { response: "" } });
        dispatch({ type: COMPANY_FILTER_APPLIED, payload: { response: 0 } });
        dispatch({ type: SOURCE_FILTER_APPLIED, payload: { response: [] } });
        dispatch({ type: INDUSTRY_FILTER_APPLIED, payload: { response: 0 } });
        dispatch({ type: FUNCTION_FILTER_APPLIED, payload: { response: 0 } });

        UpdatePageQueryParamsWithoutRefresh('/chat/', history, `?query=${question}`);
    };

    const clearChat = (source) => {
        const newAdobeData = {
            page: {
                category: {
                    primaryCategory: PRIMARY_CATEGORY.Transcript_Library,
                },
                pageInfo: {
                    pageName: PAGES.Transcript_Library_Chatbot,
                    previousPageName: PAGES.Transcript_Library_Chatbot,
                    pageURL: window.location.origin + CONFIG.UI_URL.CHAT_BOX
                }
            },
            linkName: source === "newChat" ? "New Chat" : "Home",
            link: { toolSearch: "NA" },
            internal: {
                search: { term: "NA" }
            }

        };
        if (lastQuestionRef.current) {
            // ANALYTICS.page.initialize({
            //     pageName: PAGES.Transcript_Library_Keyword_Search,
            //     previousPageName: PAGES.Transcript_Library_Chatbot,
            //     category: PRIMARY_CATEGORY.Transcript_Library,
            //     trackPageLoad: true
            // });
        }
        lastQuestionRef.current = "";
        dispatch({ type: RETRIEVAL_API_RESULTS_SUCCESS, payload: { response: [] } });
        dispatch({ type: CLEAR_TRANSCRIPT_SELECTED, payload: { response: "" } });
        dispatch({ type: CHAT_CLEAR_ALL_MESSAGES, payload: { response: "" } });
        dispatch({ type: CHAT_SUCCESS, payload: {} });
        dispatch({ type: CHAT_SET_HISTORY_ID, payload: { response: null } });
        if (fetchController !== null) {
            fetchController.abort('User clicked stop generating');
        }

        dispatch({ type: REMOVE_FETCH_CONTROLLER });
        sendToLocalStorage("eVar2ForPreviousPage", "fromChatBot");
        UpdatePageQueryParamsWithoutRefresh(source === "newChat" ? '/chat/' : '/', history, ``);
        setTimeout(function () { ANALYTICS.tl.sendEventData(newAdobeData, DTMRULE.BUTTON_LINK_CLICKS) }, 0);
    };

    useEffect(() => {
        console.log('useEffect 1');
        if (performChat && messages[messages.length - 1]?.role === ROLES.USER) {
            setPerformChat(false);
            dispatch({ type: QUERY_CHAT_API, payload: { messages, selectedEngine: TL_GPT_ENGINE, chatHistoryId, dispatch, pageReload } });
            //dispatch(chatActions.streamChat(messages, selectedEngine, scrollChat));
        }
        if (messages.length === 0) {
            //setIsSourcesCollapseOpen({});
        }

        handleScrollChatToBottom();
        //handleScrollToActiveMessage();
    }, [performChat, messages]);

    useEffect(() => {

        const eVar2 = getFromLocalStorageAsJSON("eVar2ForPreviousPage");
        removeFromLocalStorage("eVar2ForPreviousPage");

        if (!activeUserSessionId && !sessionId) {
            getCurrentUserSession();
        }
        if ((![START, PENDING].includes(entitlementApiStatus)) && isChatEnabled) {
            if (question) {
                ANALYTICS.page.initialize({
                    pageName: PAGES.Transcript_Library_Chatbot,
                    previousPageName: PAGES.Transcript_Library_Chatbot,
                    category: PRIMARY_CATEGORY.Transcript_Library, trackPageLoad: true
                });
                makeApiRequest(question, undefined, false);
            } else {
                ANALYTICS.page.initialize({
                    pageName: PAGES.Transcript_Library_Chatbot,
                    previousPageName: eVar2 === "fromKeywordSearch" ? PAGES.Transcript_Library_Keyword_Search : eVar2 === "fromSingleTranscript" ? PAGES.Detailed_Transcript_View : PAGES.Transcript_Library_Chatbot,
                    category: PRIMARY_CATEGORY.Transcript_Library, trackPageLoad: true
                });
            }
        }
    }, [entitlementApiStatus]);

    const handleScrollChatToBottom = (scrollDelay) => {
        // only scroll to bottom if auto scroll is true
        // or null - null allows us to scroll to bottom
        // on first load when auto scroll is not set yet

        setTimeout(() => {
            window.scrollTo(0, document.body.scrollHeight);
        }, scrollDelay || 0);

    };
    const handleScrollToActiveMessage = () => {
        if (chatContainerElementRef.current) {
            const chatContainer = chatContainerElementRef.current;
            chatContainer.scrollTo({
                top: chatContainer.scrollHeight,
                behavior: "instant"
            })
        }
    }
    const changeRoute = (url = "/") => {
        sendToLocalStorage("eVar2ForPreviousPage", "fromChatBot");
        history.push(url);
    };
    return (
        <Layout id="chat" layoutClass="layout__chat-section" clearChat={clearChat} activeRoute="Chat" match={match} location={location} history={history}>
            <div className={"container__chat"}>
                {/* <div className={"commandsContainer"}>
                    <ClearChatButton className={"commandButton"} onClick={clearChat} disabled={!lastQuestionRef.current || isLoading} />
                    <SettingsButton className={"commandButton"} onClick={() => setIsConfigPanelOpen(!isConfigPanelOpen)} />
                </div> */}
                <div className={"chatRoot"}>
                    <div className={`${!lastQuestionRef.current ? "chatContainer remove-padding" : "chatContainer"}`}>
                        {!lastQuestionRef.current ? (
                            <div className={"chatEmptyState"}>
                                <div className="headingText">
                                    <h1 className={"chatEmptyStateTitle"}>{"Generate insights from past interviews"}</h1>
                                    <div className="numOfTranscriptsContainer">
                                        <Image className={"numOfTranscriptsImage"} src={BenefitContent} alt="logo" />
                                        <span className={"numOfTranscriptsText"}>{NUM_OF_TRANSCRIPTS_TEXT}</span>
                                    </div>
                                </div>
                                <div className="headingText"></div>
                                <div className="headingImage">
                                    <Image className={"chatEmptyMainImage"} src={ChatMainPageImage} alt="ChatMainPageImage" />
                                </div>
                                {/* <h2 className={"chatEmptyStateSubtitle"}>{CHAT_EMPTY_SUBTITLE}</h2> */}

                            </div>
                        ) : (
                            <div className={"chatMessageStream"} ref={chatContainerElementRef}>
                                {messages.map((answer, index) => (
                                    <div key={index}>
                                        {answer.role === ROLES.USER && <UserChatMessage message={answer.content} />}
                                        {answer.role === ROLES.ASSISTANT && <div className={"chatMessageGpt"}>
                                            <Answer
                                                key={index}
                                                counter={index + 1}
                                                maxlimit={10}
                                                answer={answer}
                                                lastUserQues={lastUserQues}
                                                onThoughtProcessClicked={() => { }}
                                                onCitationViewClick={() => { }}
                                                callSlidesAPI={() => { }}
                                                citationSlides={{}}
                                                isOpenThoughtchain={false}
                                                handleThumbsUpData={() => { }}
                                                smallPreviewCitations={2}
                                            />
                                        </div>
                                        }
                                    </div>
                                ))}
                                {isResponseLoading && (
                                    <>
                                        <div className={"chatMessageGptMinWidth"}>
                                            <LoadingDotsFlashing />
                                        </div>
                                    </>
                                )}
                                {queryResponseError ? (
                                    <>
                                        <div className={"chatMessageGptMinWidth"}>
                                            <AnswerError error={errorMessage.toString()} onRetry={() => makeApiRequest(lastQuestionRef.current, true, true)} />
                                        </div>
                                    </>
                                ) : null}
                                <div ref={chatMessageStreamEnd} />
                            </div>
                        )}

                        <div className={`chatInput__wrap ${lastQuestionRef.current ? 'bottom' : ''}`}>
                            <div className="chatInput__inner">
                                {!lastQuestionRef.current &&
                                    <div className="tabsHeading">
                                        <div className="tab" onClick={() => changeRoute(CONFIG.UI_URL.KEYWORD_SEARCH)}>Keyword Search</div>

                                        <div className="tab chat-tab active">
                                            <div className="chat-tooltip">  Generate an interview guide here!
                                                <Image className="chat-arrow" src={SampleQueryArrow} alt="SampleQueryArrow" />
                                            </div>
                                            Chat
                                        </div>
                                    </div>
                                }
                                {/* <div className="chatInput__heading"><Image src={globesmall} alt="logo" />{!lastQuestionRef.current ? INPUT_EMPTY_HEADING : INPUT_HEADING}</div> */}
                                <div className={"chatInput"}>
                                    <QuestionInput
                                        questionInput={""}
                                        clearOnSend
                                        placeholder="Hey, Ask me anything! (Write clear and specific instructions, provide context for better results)"
                                        disabled={isResponseLoading}
                                        onSend={question => makeApiRequest(question, undefined, true)}
                                    />
                                </div>
                                <span className="chatInput__disclamier"><span>*</span> Do not enter any Client sensitive Information on Chat</span>

                            </div>
                            {lastQuestionRef.current && (<div className="chatInput__newChatButton">
                                <span onClick={() => clearChat("newChat")} className="chatInput__newChat">+ New Chat</span>
                            </div>)}
                        </div>
                        {!lastQuestionRef.current && (
                            <div>
                                <SampleQueries
                                    makeApiRequest={makeApiRequest}
                                    data={{
                                        chatQueries:
                                            [
                                                "What does the future of the EV market look like?",
                                                "Who are the leaders of Benefits HR Software market?",
                                                "TPIC wind energy prospects?"

                                            ],
                                        interviewGuideQueries: [
                                            "Provide top 20 most relevent questions for an interview on AI",
                                            "Provide top 20 key questions for an interview with petroleum industry expert",
                                        ]
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout >
    );
};

const mapStateToProps = (state) => ({
    activeUserSessionId: userSessionSelector.getActiveUserSession(state),
    entitlementApiStatus: entitlementSelectors.getApiCallStatus(state),
    isChatEnabled: entitlementSelectors.getEntitlementValue(state, TRANSCRIPT_LIBRARY, CHAT),
});

const mapDispatchToProps = (dispatch) => ({
    getCurrentUserSession: () => dispatch(getActiveUserSession()),
});


export const ChatBot = connect(
    mapStateToProps,
    mapDispatchToProps
)(ChatBotContainer);

export { ChatBotContainer };
